@charset "UTF-8";
/* 余白 8の倍数 */
/*
top
*/
/* ==[ .myBlockMv ]==================================== */
.myBlockMv .swiper-pagination {
  bottom: 20px !important;
}
.myBlockMv .swiper-pagination-bullet {
  background: white;
}

/* ==[ .myBlockAnchor ]==================================== */
.myBlockAnchor .sec-inner {
  padding: 50px 0;
}
@media not all and (min-width: 768px) {
  .myBlockAnchor .sec-inner {
    padding: 30px 0;
  }
}

/* ==[ .myBlockSearch ]==================================== */
.myBlockSearch {
  background: url(../img/top/search/search_bg.png) no-repeat center center;
  background-size: cover;
  height: 100%;
}
.myBlockSearch .modFlex.column2 {
  gap: 0 18px;
}
@media not all and (min-width: 768px) {
  .myBlockSearch .modFlex.column2 {
    gap: 20px 0;
    flex-direction: column;
  }
}
.myBlockSearch .modFlex__item--left {
  width: 40%;
}
@media not all and (min-width: 768px) {
  .myBlockSearch .modFlex__item--left {
    width: 100%;
  }
}
.myBlockSearch .modFlex__item--right {
  width: calc(60% - 18px);
}
@media not all and (min-width: 768px) {
  .myBlockSearch .modFlex__item--right {
    width: 100%;
  }
}
.myBlockSearch .modFlexList {
  gap: 20px 0;
  flex-direction: column;
}
@media not all and (min-width: 768px) {
  .myBlockSearch .modFlexList {
    gap: 10px 10px;
    flex-direction: row;
  }
}
@media not all and (min-width: 768px) {
  .myBlockSearch .modFlexList__item {
    width: calc(50% - 5px);
  }
}
.myBlockSearch .modFlexList__link {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(14px + 10 * (100vw - 320px) / 1600);
  font-weight: 700;
  padding: 16px 20px;
  padding-left: 112px;
  min-height: 100px;
  text-decoration: none;
  width: 100%;
  position: relative;
}
@media not all and (min-width: 768px) {
  .myBlockSearch .modFlexList__link {
    padding: 10px;
    padding-left: 80px;
    min-height: 80px;
  }
}
@media not all and (min-width: 600px) {
  .myBlockSearch .modFlexList__link {
    padding-left: 50px;
    min-height: 60px;
  }
}
.myBlockSearch .modFlexList__link::before {
  content: "";
  display: block;
  height: 72px;
  width: 72px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
@media not all and (min-width: 768px) {
  .myBlockSearch .modFlexList__link::before {
    height: 50px;
    width: 50px;
  }
}
@media not all and (min-width: 600px) {
  .myBlockSearch .modFlexList__link::before {
    height: 30px;
    width: 30px;
    left: 10px;
  }
}
.myBlockSearch .modFlexList__link.-ground::before {
  background: url(../img/top/search/search_img1.svg) no-repeat center center;
  background-size: 100% auto;
}
.myBlockSearch .modFlexList__link.-newKodate::before {
  background: url(../img/top/search/search_img2.svg) no-repeat center center;
  background-size: 100% auto;
}
.myBlockSearch .modFlexList__link.-usedKodate::before {
  background: url(../img/top/search/search_img3.svg) no-repeat center center;
  background-size: 100% auto;
}
.myBlockSearch .modFlexList__link.-renovation::before {
  background: url(../img/top/search/search_img4.svg) no-repeat center center;
  background-size: 100% auto;
}
.myBlockSearch .modImgBlock {
  text-align: center;
}
.myBlockSearch .modImgBlock img {
  width: 100%;
}

/* ==[ .myBlockPickup ]==================================== */
.myBlockPickup .modTitle {
  padding: 0 15px;
  margin: 0 auto 30px;
  width: 1200px;
  max-width: 100%;
}
.myBlockPickup .tabBlock {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
@media not all and (min-width: 768px) {
  .myBlockPickup .tabBlock {
    overflow: hidden;
    overflow-x: scroll;
  }
  .myBlockPickup .tabBlock::-webkit-scrollbar {
    background-color: #f0f0f0;
    width: 10px;
    height: 10px;
  }
  .myBlockPickup .tabBlock::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;
    height: 10px;
  }
  .myBlockPickup .tabBlock::-webkit-scrollbar-track {
    background: #F5F5F5;
  }
  .myBlockPickup .tabBlock::-webkit-scrollbar-track-piece {
    border: 1px solid #999;
    border-radius: 5px;
  }
}
.myBlockPickup .tabBlock .nav-tabs {
  border-bottom: none;
  gap: 0 18px;
  padding: 0 15px;
}
@media not all and (min-width: 768px) {
  .myBlockPickup .tabBlock .nav-tabs {
    gap: 0 10px;
    width: calc(100vw + 768px);
  }
}
.myBlockPickup .tabBlock .nav-tabs .nav-item {
  display: flex;
  text-align: center;
}
.myBlockPickup .tabBlock .nav-tabs .nav-link {
  background-color: #004891;
  border: 0;
  border-top: 3px #004891 solid;
  border-radius: 0;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  padding: 18px 5px;
  text-decoration: none;
  width: 100%;
}
.myBlockPickup .tabBlock .nav-tabs .nav-link.active {
  background-color: #F5F5F5;
  color: #004891;
}
.myBlockPickup .tab-content {
  background-color: #F5F5F5;
}
.myBlockPickup .tab-content__inner {
  padding: 50px 15px;
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
.myBlockPickup .modFlex {
  width: 100%;
}
.myBlockPickup .modFlex.column3 {
  gap: 0 18px;
}
@media not all and (min-width: 768px) {
  .myBlockPickup .modFlex.column3 {
    gap: 20px 0;
    flex-wrap: wrap;
  }
}
.myBlockPickup .modCard {
  width: 33.3333333333%;
}
@media not all and (min-width: 768px) {
  .myBlockPickup .modCard {
    width: 100%;
  }
}
.myBlockPickup .modCard .card-img.undernegotiation {
  position: relative;
}
.myBlockPickup .modCard .card-img.undernegotiation::before, .myBlockPickup .modCard .card-img.undernegotiation::after {
  position: absolute;
}
.myBlockPickup .modCard .card-img.undernegotiation::before {
  background-color: rgba(1, 72, 145, 0.3);
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.myBlockPickup .modCard .card-img.undernegotiation::after {
  border: 1px white solid;
  color: white;
  content: "商談中";
  display: inline-block;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  padding: 4px 5px 6px;
  text-align: center;
  width: 130px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.myBlockPickup .modCard .card-img-wrap.modRatio::before {
  padding-top: 68.783%;
}
.myBlockPickup .modCard .card-img .cate {
  background-color: #333;
  color: white;
  font-weight: 700;
  text-align: center;
  padding: 5px 10px;
  position: relative;
  z-index: 1;
}
.myBlockPickup .modCard .card-header,
.myBlockPickup .modCard .card-body,
.myBlockPickup .modCard .card-footer {
  padding: 16px 20px;
}
@media not all and (min-width: 768px) {
  .myBlockPickup .modCard .card-header,
.myBlockPickup .modCard .card-body,
.myBlockPickup .modCard .card-footer {
    padding: 10px;
  }
}
.myBlockPickup .modCard .card-header {
  padding-bottom: 0;
  position: relative;
}
.myBlockPickup .modCard .card-header::after {
  background-color: #333;
  content: "";
  display: block;
  margin: 0 auto;
  height: 1px;
  width: 100%;
}
.myBlockPickup .modCard .card-body {
  padding-top: 10px;
}
.myBlockPickup .modCard .card-footer {
  padding-bottom: 24px;
}
.myBlockPickup .modCard .card-title {
  font-size: calc(18px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
}

/* ==[ .myBlockRegister ]==================================== */
.myBlockRegister .sec-inner {
  padding-bottom: 0;
}
@media not all and (min-width: 768px) {
  .myBlockRegister .sec-inner {
    padding-top: 60px;
  }
}
.myBlockRegister .bg-paleorange {
  padding: 50px 80px;
  position: relative;
}
@media not all and (min-width: 992px) {
  .myBlockRegister .bg-paleorange {
    padding: 50px;
  }
}
@media not all and (min-width: 768px) {
  .myBlockRegister .bg-paleorange {
    padding: 50px 0;
  }
}
.myBlockRegister .bg-paleorange::before {
  background: url(../img/top/register/register_ttl_en.svg) no-repeat center center;
  background-size: contain;
  content: "";
  display: block;
  width: 62px;
  height: 550px;
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}
@media not all and (min-width: 768px) {
  .myBlockRegister .bg-paleorange::before {
    background: url(../img/top/register/register_ttl_en_sp.svg) no-repeat center center;
    background-size: contain;
    width: calc(100% - 100px);
    height: 62px;
    left: 50%;
    top: -30px;
    transform: translate(-50%, 0);
  }
}
@media not all and (min-width: 768px) {
  .myBlockRegister .modTitle img {
    width: 64.453125vw;
    max-width: 495px;
    min-width: 250px;
  }
}
.myBlockRegister .modFlex {
  counter-reset: card_num;
  position: relative;
}
.myBlockRegister .modFlex.column3 {
  gap: 0 40px;
}
@media not all and (min-width: 768px) {
  .myBlockRegister .modFlex.column3 {
    flex-wrap: wrap;
    gap: 30px 0;
  }
}
.myBlockRegister .modFlex.column3 .modFlex__item {
  padding-top: 38px;
  width: calc(33.3333333333% - 18px);
  position: relative;
}
@media not all and (min-width: 768px) {
  .myBlockRegister .modFlex.column3 .modFlex__item {
    padding-top: 34px;
    width: 100%;
  }
}
.myBlockRegister .modFlex.column3 .modFlex__item::before {
  background-color: white;
  border-radius: 100%;
  color: #EC6C00;
  counter-increment: card_num;
  content: "" counter(card_num);
  display: block;
  font-family: "Oswald", Arial, Helvetica, sans-serif;
  font-size: calc(20px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  text-align: center;
  padding: 5px;
  width: 74px;
  height: 74px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.myBlockRegister .modFlex .modCard {
  padding: 24px 18px 40px;
}
.myBlockRegister .modFlex .modCard .card-img {
  text-align: center;
  margin-bottom: 24px;
}
.myBlockRegister .modFlex .modCard .card-img img {
  width: auto;
}
.myBlockRegister .modFlex .modCard .card-title {
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  text-align: center;
}
@media not all and (min-width: 768px) {
  .myBlockRegister .modFlex .modCard .card-title {
    text-align: left;
  }
}

/* ==[ .myBlockAboutus ]==================================== */
.myBlockAboutus .modHeader {
  height: 596px;
  overflow: hidden;
  position: relative;
}
@media not all and (min-width: 768px) {
  .myBlockAboutus .modHeader {
    height: auto;
    margin-bottom: 50px;
  }
}
.myBlockAboutus .modHeader img {
  width: 100%;
  height: 100%;
}
.myBlockAboutus .modHeader .modTitle {
  background-color: white;
  margin-bottom: 0;
  padding: 56px 15px 30px;
  width: 1200px;
  max-width: calc(100% - 30px);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media not all and (min-width: 768px) {
  .myBlockAboutus .modHeader .modTitle {
    display: none;
  }
}
.myBlockAboutus .contxt {
  text-align: center;
  line-height: 2;
}
@media not all and (min-width: 768px) {
  .myBlockAboutus .contxt {
    text-align: left;
  }
}
.myBlockAboutus .bnrBlock {
  margin-top: 100px;
  text-align: center;
}
@media not all and (min-width: 768px) {
  .myBlockAboutus .bnrBlock {
    margin-top: 50px;
  }
}
.myBlockAboutus .bnrBlock img {
  width: auto;
}