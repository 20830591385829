@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

/*
top
*/

$PAGE_ID: top;
$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';
$IMAGE_PATH: '#{$PATH}img/#{$PAGE_ID}/';

/* ==[ .myBlockMv ]==================================== */
.myBlockMv {

  .swiper-pagination {
    bottom: 20px !important;

    &-bullet {
      background: white;
    }
  }
}

/* ==[ .myBlockAnchor ]==================================== */
.myBlockAnchor {

  .sec-inner {
    padding: 50px 0;

    @include mixin.tabletP {
      padding: 30px 0;
    }
  }
}

/* ==[ .myBlockSearch ]==================================== */
.myBlockSearch {
  background: url(#{mixin.set_image_path('top/search/search_bg.png')}) no-repeat center center;
  background-size: cover;
  height: 100%;

  .modFlex {

    &.column2 {
      gap: 0 18px;

      @include mixin.tabletP {
        gap: 20px 0;
        flex-direction: column;
      }
    }

    &__item {
      &--left {
        width: 40%;

        @include mixin.tabletP {
          width: 100%;
        }
      }

      &--right {
        width: calc(60% - 18px);

        @include mixin.tabletP {
          width: 100%;
        }
      }
    }
  }

  .modFlexList {
    gap: 20px 0;
    flex-direction: column;

    @include mixin.tabletP {
      gap: 10px 10px;
      flex-direction: row;
    }

    &__item {
      @include mixin.tabletP {
        width: calc(100% / 2 - 5px);
      }
    }

    &__link {
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: calc(14px + 10 * (100vw - 320px) / 1600);
      font-weight: 700;
      padding: 16px 20px;
      padding-left: 112px;
      min-height: 100px;
      text-decoration: none;
      width: 100%;
      position: relative;

      @include mixin.tabletP {
        padding: 10px;
        padding-left: 80px;
        min-height: 80px;
      }

      @include mixin.sp {
        padding-left: 50px;
        min-height: 60px;
      }

      &::before {
        content: "";
        display: block;
        height: 72px;
        width: 72px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);

        @include mixin.tabletP {
          height: 50px;
          width: 50px;
        }

        @include mixin.sp {
          height: 30px;
          width: 30px;
          left: 10px;
        }
      }

      &.-ground {
        &::before {
          background: url(#{mixin.set_image_path('top/search/search_img1.svg')}) no-repeat center center;
          background-size: 100% auto;
        }
      }

      &.-newKodate {
        &::before {
          background: url(#{mixin.set_image_path('top/search/search_img2.svg')}) no-repeat center center;
          background-size: 100% auto;
        }
      }

      &.-usedKodate {
        &::before {
          background: url(#{mixin.set_image_path('top/search/search_img3.svg')}) no-repeat center center;
          background-size: 100% auto;
        }
      }

      &.-renovation {
        &::before {
          background: url(#{mixin.set_image_path('top/search/search_img4.svg')}) no-repeat center center;
          background-size: 100% auto;
        }
      }
    }
  }

  .modImgBlock {
    text-align: center;

    img {
      width: 100%;
    }
  }
}

/* ==[ .myBlockPickup ]==================================== */
.myBlockPickup {
  .modTitle {
    padding: 0 15px;
    margin: 0 auto 30px;
    width: 1200px;
    max-width: 100%;
  }

  .tabBlock {
    margin: 0 auto;
    width: 1200px;
    max-width: 100%;

    @include mixin.tabletP {
      overflow: hidden;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        background-color: #f0f0f0;
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #999;
        border-radius: 5px;
        height: 10px;
      }

      &::-webkit-scrollbar-track {
        background: map_get(variables.$COLOR, gray);
      }

      &::-webkit-scrollbar-track-piece {
        border: 1px solid #999;
        border-radius: 5px;
      }
    }

    .nav-tabs {
      border-bottom: none;
      gap: 0 18px;
      padding: 0 15px;

      @include mixin.tabletP {
        gap: 0 10px;
        width: calc(100vw + 768px);
      }

      .nav-item {
        display: flex;
        text-align: center;
      }

      .nav-link {
        background-color: variables.$COLOR_MAIN;
        border: 0;
        border-top: 3px variables.$COLOR_MAIN solid;
        border-radius: 0;
        color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-size: calc(16px + 2 * (100vw - 320px) / 1600);
        font-weight: 700;
        padding: 18px 5px;
        text-decoration: none;
        width: 100%;

        &.active {
          background-color: map_get(variables.$COLOR, gray);
          color: variables.$COLOR_MAIN;
        }
      }
    }
  }

  .tab-content {
    background-color: map_get(variables.$COLOR, gray);

    &__inner {
      padding: 50px 15px;
      margin: 0 auto;
      width: 1200px;
      max-width: 100%;
    }
  }

  .modFlex {
    width: 100%;

    &.column3 {
      gap: 0 18px;

      @include mixin.tabletP {
        gap: 20px 0;
        flex-wrap: wrap;
      }
    }
  }

  .modCard {
    width: calc(100% / 3);

    @include mixin.tabletP {
      width: 100%;
    }

    &__link {

    }

    .card-img {
      &.undernegotiation {
        position: relative;

        &::before,
        &::after {
          position: absolute;
        }

        &::before {
          background-color: rgba(1, 72, 145, .3);
          content: "";
          display: block;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &::after {
          border: 1px white solid;
          color: white;
          content: "商談中";
          display: inline-block;
          font-size: calc(16px + 2 * (100vw - 320px) / 1600);
          padding: 4px 5px 6px;
          text-align: center;
          width: 130px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }
      }

      &-wrap {
        &.modRatio {
          &::before {
            padding-top: 68.7830%;
          }
        }
      }

      .cate {
        background-color: variables.$COLOR_TEXT;
        color: white;
        font-weight: 700;
        text-align: center;
        padding: 5px 10px;
        position: relative;
        z-index: 1;
      }
    }

    .card-header,
    .card-body,
    .card-footer {
      padding: 16px 20px;

      @include mixin.tabletP {
        padding: 10px;
      }
    }

    .card-header {
      padding-bottom: 0;
      position: relative;

      &::after {
        background-color: variables.$COLOR_TEXT;
        content: "";
        display: block;
        margin: 0 auto;
        height: 1px;
        width: 100%;
      }
    }

    .card-body {
      padding-top: 10px;
    }

    .card-footer {
      padding-bottom: 24px;
    }


    .card-title {
      font-size: calc(18px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;
    }
  }
}

/* ==[ .myBlockRegister ]==================================== */
.myBlockRegister {

  .sec-inner {
    padding-bottom: 0;

    @include mixin.tabletP {
      padding-top: 60px;
    }
  }

  .bg-paleorange {
    padding: 50px 80px;
    position: relative;

    @include mixin.tabletL {
      padding: 50px;
    }

    @include mixin.tabletP {
      padding: 50px 0;
    }

    &::before {
      background: url(#{mixin.set_image_path('top/register/register_ttl_en.svg')}) no-repeat center center;
      background-size: contain;
      content: "";
      display: block;
      width: 62px;
      height: 550px;
      position: absolute;
      left: -30px;
      top: 50%;
      transform: translateY(-50%);

      @include mixin.tabletP {
        background: url(#{mixin.set_image_path('top/register/register_ttl_en_sp.svg')}) no-repeat center center;
        background-size: contain;
        width: calc(100% - 100px);
        height: 62px;
        left: 50%;
        top: -30px;
        transform: translate(-50%, 0);
      }
    }
  }

  .modTitle {

    img {
      @include mixin.tabletP {
        width: calc(495 / 768 * 100vw);
        max-width: 495px;
        min-width: 250px;
      }
    }
  }

  .modFlex {
    counter-reset: card_num;
    position: relative;



    &.column3 {
      gap: 0 40px;

      @include mixin.tabletP {
        flex-wrap: wrap;
        gap: 30px 0;
      }

      .modFlex__item {
        padding-top: 38px;
        width: calc(100% / 3 - 18px);
        position: relative;

        @include mixin.tabletP {
          padding-top: 34px;
          width: 100%;
        }

        &::before {
          background-color: white;
          border-radius: 100%;
          color: map_get(variables.$COLOR, orange);
          counter-increment: card_num;
          content: "" counter(card_num);
          display: block;
          font-family: variables.$FONT_ALPHA;
          font-size: calc(20px + 2 * (100vw - 320px) / 1600);
          font-weight: 700;
          text-align: center;
          padding: 5px;
          width: 74px;
          height: 74px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .modCard {
      padding: 24px 18px 40px;

      .card-img {
        text-align: center;
        margin-bottom: 24px;

        img {
          width: auto;
        }
      }

      .card-title {
        font-size: calc(16px + 2 * (100vw - 320px) / 1600);
        font-weight: 700;
        text-align: center;

        @include mixin.tabletP {
          text-align: left;
        }
      }
    }
  }
}

/* ==[ .myBlockAboutus ]==================================== */
.myBlockAboutus {

  .modHeader {
    height: 596px;
    overflow: hidden;
    position: relative;

    @include mixin.tabletP {
      height: auto;
      margin-bottom: 50px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .modTitle {
      background-color: white;
      margin-bottom: 0;
      padding: 56px 15px 30px;
      width: 1200px;
      max-width: calc(100% - 30px);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      @include mixin.tabletP {
        display: none;
      }
    }
  }

  .contxt {
    text-align: center;
    line-height: 2;

    @include mixin.tabletP {
      text-align: left;
    }
  }

  .bnrBlock {
    margin-top: 100px;
    text-align: center;

    @include mixin.tabletP {
      margin-top: 50px;
    }

    img {
      width: auto;
    }
  }
}
